import React from 'react'
import { Table } from 'reactstrap'

import EmptyState from 'components/EmptyState/EmptyState'


const CasinoSupportTypes = ({ supportTypes }) => {
  const emptyStateProps = { type: 'tab' }
  
  return(
    <>
      {supportTypes.length > 0 ?
        supportTypes.map(supportType => {
          const { name, contact, languages, vip } = supportType
          const languagesValues = languages.map(language => { return language.name })

          return (
            <Table className='mt-4' id='casino-details-modal' responsive borderless>
              <tbody>
                <tr>
                  <th className='text-right pr-4'>Type</th>
                  <td>{name}</td>
                </tr>
                <tr>
                  <th className='text-right pr-4'>Contact Info</th>
                  <td>{contact}</td>
                </tr>
                <tr>
                  <th className='text-right pr-4'>Languages</th>
                  <td>{languagesValues.join(', ')}</td>
                </tr> 
                <tr>
                  <th className='text-right pr-4'>VIP</th>
                  <td>
                    {vip ? 
                      <i className='fas fa-check' style={{color:'green'}} />
                    :
                      <i className='fas fa-times' style={{color:'red'}} />
                    }
                  </td>
                </tr>
              </tbody>
              <hr id='horizontal-rule' />
            </Table>
          )})
        :
        <EmptyState {...emptyStateProps}>
          <>
            <p>
              Casino Support Types are underway, we are finalizing the puzzle!{'\n'}
              Our team is working to update all Casino Support Types.
            </p>
            <p>
              <strong>Stay tuned for more!</strong> 
            </p>
          </>
        </EmptyState>
      }
    </>
  )
}

export default CasinoSupportTypes