import React, { useState, useEffect } from 'react'
import { Row, Label, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import Switch from 'react-bootstrap-switch'

import PaymentMethodTable from './PaymentMethodTable'
import CurrencyTable from './CurrencyTable'
import SupportTypeTable from './SupportTypeTable'
import SoftwareProviderTable from './SoftwareProviderTable'

const UpdatesDetails = ({ update, modal, updateDetails, closeModal }) => {
  const { type } = update  
  const { name, payment_method_type, gambling_products, symbol, contact, support_type_category, languages, vip, owner, established_year, 
    online_parlour_games, online_scratchcard_games, online_slot_games, online_table_games, online_video_poker_games } = updateDetails

  const [method, setMethod] = useState(false)
  const [gamblingProductsFiltered, setGamblingProductsFiltered] = useState([])

  const supportTypeCategory = type === 'Support Type' ? support_type_category : null
 
  let languagesValues = []
  let languages3Values = []
  
  if (type === 'Support Type' && languages) {
    languagesValues = languages.map(language => { return language.name })
    languages3Values = languagesValues.slice(0, 3)
  }
  const [showAllLanguages, setShowAllLanguages] = useState(false)

  let gamesValues = []
  let games3Values = []
  
  if (type === 'Software Provider' && online_scratchcard_games && online_slot_games && online_table_games && online_video_poker_games) {
    const games = online_parlour_games.concat(online_scratchcard_games, online_slot_games, online_table_games, online_video_poker_games)
    
    gamesValues = games.map(game => { return game.name })
    games3Values = gamesValues.slice(0, 3)
  }
  const [showAllGames, setShowAllGames] = useState(false)

  const handleSwitch = (elem, state) => setMethod(state)

  const tableDetails = type => {
    switch (type) {
      case 'Currency':
        return <CurrencyTable symbol={symbol} />
      case 'Support Type':
        return <SupportTypeTable {...supportTypeProps} />
      case 'Software Provider':
        return <SoftwareProviderTable {...softwareProviderProps} />
      default:
        return <>
          <Row className='mt-4'>
            <Col>
            <Label className='mr-3'>Withdrawal</Label>
            <Switch
              onText=''
              offText=''
              defaultValue={false}
              value={method}
              onChange={(el, state) => handleSwitch(el, state)} 
            />
            <Label className='ml-3'>Deposit</Label>
            </Col>
          </Row>
    
          <PaymentMethodTable gamblingProducts={gamblingProductsFiltered} />
        </>
    }
  }

  useEffect(() => {
    if (gambling_products) {
      const filtered = method ? 
        gambling_products.filter(product => product.method === 'Deposit')
      :
        gambling_products.filter(product => product.method === 'Withdrawal')
      
      setGamblingProductsFiltered(filtered)
    }

    if (!modal) {
      setShowAllLanguages(false)
      setShowAllGames(false)
    }
  }, [gambling_products, method, modal])

  const supportTypeProps = { contact, showAllLanguages, languages3Values, languagesValues, setShowAllLanguages, vip }
  const softwareProviderProps = { owner, established_year, showAllGames, games3Values, gamesValues, setShowAllGames }

  return (
    <Modal 
      isOpen={modal} 
      toggle={closeModal}
      centered
    >
      <ModalBody>
        <div className='text-muted'>
          {type}
        </div>
        {supportTypeCategory ?
          <h4 className='text-primary mt-2'>{supportTypeCategory.name}</h4>
        :
          <h4 className='text-primary mt-2'>{name}</h4>
        }
        
        {type === 'Payment Method' &&
          <div className='mt-4 text-muted'>
            Type
            <span className='ml-5'>{payment_method_type ? payment_method_type.name : ''}</span>
          </div>
        }
        {tableDetails(type)}
      </ModalBody>
      <ModalFooter>
        <Button style={{ color: '#000000', backgroundColor: '#F3F3F3' }} onClick={closeModal}>
            Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdatesDetails
