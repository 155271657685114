import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody, 
  CardTitle,
  Button
} from 'reactstrap'

import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import BonusesTable from './BonusesTable'
import BonusesServices from 'services/bonuses.services'
import { setBonuses } from 'redux/bonus/bonus.actions'

const Bonuses = () => {
  const bonuses = useSelector(state => state.bonus.bonuses)
  const userRole = useSelector(state => state.user.currentUser.role.name)

  const dispatch = useDispatch()

  const fetchBonuses = useCallback(() => {
    BonusesServices.getBonuses().then(res => {
      dispatch(setBonuses(res.data.data))
    })
  }, [dispatch])

  useEffect(() => { 
    fetchBonuses()
  }, [fetchBonuses])

  const tableProps = { bonuses, userRole }

  return(
    <>
      <PanelHeader size='sm' />
      <div className='content'>
        <Row>
          <Col md='8'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>List of Bonuses</CardTitle>
               {userRole === 'Content Admin' &&
                  <Button 
                    color='primary' 
                    className='mr-5 float-right'
                  > 
                    Create New Bonus
                  </Button>
                }
              </CardHeader>
              <CardBody>
                <BonusesTable {...tableProps} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Bonuses