import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardHeader, CardBody, CardFooter, CardTitle } from 'reactstrap'

import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import Pagination from 'components/Pagination/Pagination'
import EmptyState from 'components/EmptyState/EmptyState'
import UpdatesNav from './UpdatesNav'
import General from './General'
import Bonus from './Bonus'

import UpdatesServices from 'services/updates.services'

const Updates = props => {
  const [activeNav, setActiveNav] = useState('general')
  const [updates, setUpdates] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [paginationLinks, setPaginationLinks] = useState([])
  const [totalPages, setTotalPages] = useState(1) 
  const [filtersSelected, setFiltersSelected] = useState([])
  const [emptyState, setEmptyState] = useState(false)
  const [emptyFilterState, setEmptyFilterState] = useState(false)
  const [bonuses, setBonuses] = useState([])
  const [bonusFilter, setBonusFilter] = useState('start_soon')

  useEffect(() => {
    const type = filtersSelected.join()

    if (activeNav === 'general') {
      UpdatesServices.getUpdates(activePage, type)
      .then(res => {
        if (res.data.data.length > 0) {
          setTotalPages(Math.ceil(res.data.meta.totalRecords/20))
          setPaginationLinks(Array(Math.ceil(res.data.meta.totalRecords/20)).fill(1).map((x, y) => x + y))
          setEmptyState(false)
          setEmptyFilterState(false)
        } else {
          type ? setEmptyFilterState(true) : setEmptyState(true)
          setTotalPages(1)
        }
        setUpdates(res.data.data)
      })
    } else {
      UpdatesServices.getUpdatesBonus(activePage, bonusFilter)
      .then(res => {
        if (res.data.data.length > 0) {
          setTotalPages(Math.ceil(res.data.meta.totalRecords/20))
          setPaginationLinks(Array(Math.ceil(res.data.meta.totalRecords/20)).fill(1).map((x, y) => x + y))
          setEmptyFilterState(false)
        } else {
          setEmptyFilterState(true)
          setTotalPages(1)
        }
        setBonuses(res.data.data)
      })
    }
  }, [activePage, filtersSelected, activeNav, bonusFilter])

  const navProps = { activeNav, setActiveNav, setActivePage } 
  const filtersProps = { filtersSelected, setFiltersSelected, setActivePage }
  const generalProps = { updates, filtersProps, emptyFilterState }
  const paginationProps = { activePage, setActivePage, paginationLinks }
  const emptyStateProps = { props, type: 'list' }
  const bonusProps = { bonuses, bonusFilter, setBonusFilter, emptyFilterState, setActivePage }

  return (
    <>
      <PanelHeader size='sm' />
      <div className='content'>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Updates</CardTitle>
                {updates && !emptyState &&
                  <UpdatesNav {...navProps} />
                }
              </CardHeader>
              <CardBody>
                {updates && !emptyState ?
                  <>
                    {activeNav === 'general' ?
                      <General {...generalProps} />
                      :
                      <Bonus {...bonusProps} />
                    } 
                  </>
                :
                  <EmptyState {...emptyStateProps}>
                    <>
                      <p>All the latest industry Updates are in your hands!</p>
                      <p>
                        Select the <strong>Brand(s)</strong> you prefer and get all their 
                        latest <strong>updates</strong> in <strong>one place.</strong>
                      </p>
                    </>
                  </EmptyState>
                }
              </CardBody>
              {totalPages > 1 &&
                <CardFooter>
                  <Pagination {...paginationProps} />
                </CardFooter>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Updates
