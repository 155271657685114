import axios from './axios'

const getUpdates = (page, type) => axios.get(`/admin/updates/20/${(page-1)*20}`, {
  headers: {
    type: type
  }
})
const getUpdateDetails = (update, type) => axios.get('/admin/updates/details', {
  headers: {
    entityId: update.entity_id,
    type: type,
    entryId: update.entry_id
  }
})
const getUpdatesBonus = (page, period) => axios.get(`/admin/updates/20/${(page-1)*20}`, {
  headers: {
    entity: 'bonus',
    period: period
  }
})
const getBonusDetails = id => axios.get('/admin/updates/details', {
  headers: {
    entityId: id,
    type: 'bonus'
  }
})
const addBrands = data => axios.post('/admin/brands/link', data)

const UpdatesServices = {
  getUpdates,
  getUpdateDetails,
  getUpdatesBonus,
  getBonusDetails,
  addBrands
}

export default UpdatesServices
