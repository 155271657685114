import { CasinoActionTypes } from './casino.types'

const { SET_CASINOS, ADD_CASINO, UPDATE_CASINO, SET_CASINO, SET_CASINO_OPTIONS } = CasinoActionTypes

export const setCasinos = casinos => ({
  type: SET_CASINOS,
  payload: casinos
})

export const addCasino = casino => ({
  type: ADD_CASINO,
  payload: casino
})

export const updateCasino = casino => ({
  type: UPDATE_CASINO,
  payload: casino
})

export const setCasino = casino => ({
  type: SET_CASINO,
  payload: casino
})

export const setCasinoOptions = options => ({
  type: SET_CASINO_OPTIONS,
  payload: options
})


