
import Swal from 'sweetalert2'

import './show-alert.styles.scss'

const UseShowAlert = (icon, title, text) => {
  if(icon === 'error'){
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Contact Us',
      confirmButtonColor: '#f96332',
      showCancelButton: true,
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        window.open('https://ginbits.com/contact/')
      }
    })
  } else {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Try again',
      confirmButtonColor: '#f96332',
      customClass: 'swalWarning'
    })
  }
}

export default UseShowAlert