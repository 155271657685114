import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react' 
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.4.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={hist}>
        <Switch>
          <Route
            path="/admin"
            render={(props) => {
              return <AdminLayout {...props} />;
            }}
          />
          <Route
            path="/auth"
            render={(props) => {
              return <AuthLayout {...props} />;
            }}
          />
          <Redirect to="/auth/login-page" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
