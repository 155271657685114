import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

var ps;

class Admin extends React.Component {
  state = {
    sidebarMini: true,
    backgroundColor: "white",
    sidebarRoutes: []
  };
  notificationAlert = React.createRef();
  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }

    this.createSidebarMenu()
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  createSidebarMenu = () => {
    const sidebarOptions = this.props.role === 'Affiliate Admin' ?
      ['/updates', '/brands', '/casinos']
    :
      ['/casinos', '/bonuses']

    let sidebarRoutes = []
    
    sidebarOptions.map(option => {
      sidebarRoutes.push(routes.find(route => route.path === option))
      return sidebarRoutes
    })

    this.setState({...this.state, sidebarRoutes: sidebarRoutes})
  }

  minimizeSidebar = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {
    return (
      <div className="wrapper">
        <NotificationAlert ref={this.notificationAlert} />
        <Sidebar
          {...this.props}
          routes={this.state.sidebarRoutes}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={this.state.backgroundColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminNavbar
            {...this.props}
            brandText={this.getActiveRoute(routes)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect 
              from="/admin" 
              to = {this.props.role === 'Affiliate Admin' ?
                  "/admin/updates"
                :
                  "/admin/casinos"
              }
            />
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.user.currentUser.role.name
  }
}

export default connect(mapStateToProps)(Admin)