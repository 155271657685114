import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import routes from "routes.js";
import UsersServices from 'services/users.services'

class Auth extends React.Component {
  state = {
    filterColor: "yellow",
    registerNow: false
  };

  componentDidMount() {
    this.getRestistrationToken()
  }

  getRestistrationToken = () => {
    const path = this.props.location.pathname

    if (path.includes('confirm/registration')) {
      const token = path.split('registration/').pop()

      UsersServices.confirmRegistration(token)
      .then(res => {
        this.setState({ ...this.state, registerNow: true })
      }) 
    } 
  }
  
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleColorClick = (color) => {
    this.setState({ filterColor: color });
  };
  render() {
    return (
      <>
        <div className="wrapper wrapper-full-page">
          <div
            className="full-page section-image"
            filter-color={this.state.filterColor}
          >
            <Switch>
              {this.getRoutes(routes)}
              <Redirect 
                from="/auth/confirm/registration" 
                to={{
                  pathname: "/auth/login-page",
                  state: { 
                    registerNow: this.state.registerNow
                  }
                }}
              />
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default Auth;
