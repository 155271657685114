import React from 'react'
import { Button } from 'reactstrap'

const filters = [
  { value: 'payment_method', label: 'Payment Method' }, 
  { value: 'software_provider', label: 'Software Providers' }, 
  { value: 'currency', label: 'Currencies' }, 
  { value: 'support_type', label: 'Support Type' }
]

const GeneralFilters = ({ filtersSelected, setFiltersSelected, setActivePage }) => {
  const onFilter = filter => {
    setActivePage(1)
    
    if (filtersSelected.includes(filter)) {
      const removedFilter = filtersSelected.filter(el => el !== filter)
      setFiltersSelected(removedFilter)
    } else {
      setFiltersSelected([...filtersSelected, filter])
    }
  }

  return (
    <>
      {filters.map((filter, index) => (
        <Button 
          key={index} 
          className='btn-neutral' 
          color={filtersSelected.includes(filter.value) ? 'primary' : 'tumblr'} 
          onClick={() => onFilter(filter.value)}
        >
          {filter.label}
        </Button>
        ))
      }
    </>
  )
}

export default GeneralFilters