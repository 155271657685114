import axios from './axios'

const getBrands = () => axios.get('/admin/brands')
const getGroups = () => axios.get('/admin/groups')
const getCertifications = () => axios.get('/admin/certifications')
const getCountries = () => axios.get('/admin/countries')
const getPrograms = () => axios.get('/admin/affiliate_programs')
const getLicenses = () => axios.get('/admin/licenses')
const getProviders = () => axios.get('/admin/software_providers')
const getLanguages = () => axios.get('/admin/languages')
const getStates = () => axios.get('/admin/states')
const getPaymentMethods = () => axios.get('/admin/payment_methods')
const getCurrencies = () => axios.get('/admin/currencies')
const getSupportTypes = () => axios.get('/admin/support_type_categories')

const addCasino = data => axios.post('/admin/gambling_products', data)
const getCasino = id => axios.get(`/admin/gambling_products/${id}`)
const saveCasino = (id, data) => axios.put(`/admin/gambling_products/${id}`, data)
const getCasinos = (page, dissolved) => axios.get(`/admin/gambling_products/20/${(page-1)*20}`, {
  headers: {
    dissolved: dissolved
  }
})

const getCasinosSearched = (search, page, dissolved) => axios.get(`/admin/gambling_products/search/20/${(page-1)*20}`, {
  headers: {
    name: search,
    dissolved: dissolved
  }
})

const CasinosServices = {
  getBrands,
  getGroups,
  getCertifications,
  getCountries,
  getPrograms,
  getLicenses,
  getProviders,
  getLanguages,
  getStates,
  getPaymentMethods,
  getCurrencies,
  getSupportTypes,
  addCasino,
  getCasino,
  saveCasino,
  getCasinos,
  getCasinosSearched
}

export default CasinosServices
