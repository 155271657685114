import React from 'react'
import { Table } from 'reactstrap'

const CurrencyTable = ({ symbol }) => (
  <Table responsive borderless>
    <tbody>
      <tr>
        <td className='w-25'>Symbol</td>
        <td className='text-left'>{symbol}</td>
      </tr>
    </tbody>
  </Table>
)

export default CurrencyTable