import React, { useState } from 'react'
import { Table, Button } from 'reactstrap'

import UpdatesDetails from './UpdatesDetails/UpdatesDetails'
import UpdatesServices from 'services/updates.services'

const types = {
  payment_method: 'Payment Method',
  currency: 'Currency',
  support_type: 'Support Type',
  software_provider: 'Software Provider'
}

const GeneralTable = ({ updates }) => {
  const [update, setUpdate] = useState({})
  const [updateDetails, setUpdateDetails] = useState({})
  const [modal, setModal] = useState(false)
  const typesValues = Object.values(types)

  const closeModal = () => setModal(false)

  const openView = update => {    
    const type = Object.keys(types)[Object.values(types).indexOf(update.type)]

    UpdatesServices.getUpdateDetails(update, type)
      .then(res => {
        setUpdate(update)
        setUpdateDetails(res.data.data[0])
        setModal(true)
      })
  }

  return (
    <div className='mx-4'>
      {updateDetails &&
        <UpdatesDetails 
          update={update} 
          modal={modal}
          closeModal={closeModal}
          updateDetails={updateDetails}
        />
      }
      
      <Table responsive>
        <thead className='text-primary' >
          <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Updates</th>
          <th className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {updates.map((update, index) => (
            <tr key={update.id}>
            <td>{update.updated_at.split(' ')[0]}</td>
            <td>{update.type}</td>
            <td>{update.description}</td>
            <td className='text-center' >
              <Button
                className='btn-icon btn-neutral'
                color='info'
                type='button'
                onClick={() => openView(update)}
              >
                {update.action === 'add' && typesValues.some(type => type === update.type) &&
                  <i className='far fa-eye' />
                }
              </Button> 
            </td>
            </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default GeneralTable