import { BonusActionTypes } from './bonus.types'

const INITIAL_STATE = {
  bonuses: null
}

const { SET_BONUSES } = BonusActionTypes

const bonusReducer = (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case SET_BONUSES:
      return {
        ...state,
        bonuses: action.payload
      }
      
    default:
      return state
  }
}

export default bonusReducer