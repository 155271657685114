import React, { useState } from 'react'
import { Table, Modal, ModalBody, ModalFooter, Button, Popover, PopoverBody } from 'reactstrap'

import SingleRows from './SingleRows'
import TierBonus from './TierBonus'


const BonusDetails = ({ modal, bonus, bonusDetails, closeModal }) => {
  const { bonus_type } = bonus 
  const { expiration_date, season, name, promotional_period, min_deposit_amount, 
    max_bonus_amount, wager, max_cashout, coupon_code, live_chat, terms_url, descendants } = bonusDetails
  
  const [copyPopover, setCopyPopover] = useState(false)
  
  const togglePopover = () => setCopyPopover(!copyPopover)
  
  const copyLink = () => {
    navigator.clipboard.writeText(terms_url)
    togglePopover()
  }

  const singleProps = { min_deposit_amount, max_bonus_amount, wager, max_cashout, coupon_code, live_chat }

  return (
    <Modal 
      isOpen={modal} 
      toggle={closeModal}
      centered
    >
      <ModalBody>
        <div className='text-muted'>{promotional_period}</div>
        <h4 className='text-primary mt-2'>{name!=='' ? name : '-'}</h4>
        
        <Table responsive borderless className='mb-0'>
          <tbody>
            <tr>
              <td className='w-25'>Expiring Date</td>
              <td className='text-left pl-5'>{expiration_date!=='' ? expiration_date : '-'}</td>
            </tr>
            <tr>
              <td className='w-25'>Season</td>
              <td className='text-left pl-5'>{season ? season.name : '-'}</td>
            </tr>
            {bonus_type !== 'Tier' &&
              <SingleRows {...singleProps} />
            }
          </tbody>
        </Table>
        <div
          style={{color:'#4C90E4', cursor: 'pointer', fontSize: '12px', marginLeft: '0.6em'}} 
          onClick={copyLink}
        >
          <i className='fas fa-link' />
          <span id='copyPopover' > Copy T&C URL</span>
        </div>
        <Popover
          placement='right'
          id='copyPopover' 
          isOpen={copyPopover}
          target='copyPopover'
          trigger='legacy'
          toggle={togglePopover}
          className='popover-primary'
        >
          <PopoverBody>
            T&C URL copied
          </PopoverBody>
        </Popover> 

        {bonus_type === 'Tier' &&
          <TierBonus descendants={descendants} />
        }
      </ModalBody>
      <ModalFooter>
        <Button style={{ color: '#000000', backgroundColor: '#F3F3F3' }} onClick={closeModal}>
            Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BonusDetails
