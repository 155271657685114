import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationActive = ({ activePage, setActivePage, paginationLinks }) => {
  const onNext = () => setActivePage(activePage + 1)
  const onPrevious = () => setActivePage(activePage - 1)

  const paginationFirstLinks = activePage > 1 ? paginationLinks.slice(activePage-2, activePage+1) : paginationLinks.slice(0, 2)
  const paginationLastLink = paginationLinks.slice(-1)[0]

  return(
    <div className='float-right'>
      <Pagination className='float-right'>  
        <PaginationItem 
          disabled={activePage === 1 ? true : false}
        >
          <PaginationLink onClick={() => onPrevious()}>
            Previous
          </PaginationLink>
        </PaginationItem>

        {activePage > 2 &&
          <>
            <PaginationItem>
              <PaginationLink onClick={() => setActivePage(1)}>
                1
              </PaginationLink>
            </PaginationItem>
            {activePage > 3 &&
              <PaginationItem disabled>
                <PaginationLink>
                  ...
                </PaginationLink>
              </PaginationItem>
            }
          </>
        }

        {paginationFirstLinks.map((link, index) => (
          <PaginationItem 
            key={index} 
            active={activePage === link ? true : false}
          >
            <PaginationLink onClick={() => setActivePage(link)}>
                {link}
            </PaginationLink>
          </PaginationItem>
        ))}

        {activePage < paginationLinks.length - 2 &&
          <PaginationItem disabled>
            <PaginationLink>
              ...
            </PaginationLink>
          </PaginationItem>
        }

        {activePage < paginationLinks.length - 1 &&
          <PaginationItem >
            <PaginationLink  onClick={() => setActivePage(paginationLastLink)}>
              {paginationLastLink}
            </PaginationLink>
          </PaginationItem>
        }
        
        <PaginationItem 
          disabled={activePage === paginationLinks[paginationLinks.length - 1] ? true : false}
        >
          <PaginationLink  onClick={() => onNext()}>
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  )
}

export default PaginationActive