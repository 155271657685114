import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const TierPagination = ({ activePage, setActivePage, paginationLinks }) => (
  <div className='float-right'>
    <Pagination className='float-right'>  
      {paginationLinks.map((link, index) => (
        <PaginationItem 
          key={index} 
          active={activePage === link ? true : false}
        >
          <PaginationLink onClick={() => setActivePage(link)}>
              {link}
          </PaginationLink>
        </PaginationItem>
      ))}
    </Pagination>
  </div>
)

export default TierPagination