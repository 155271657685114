import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'


const UpdatesNav = ({ activeNav, setActiveNav, setActivePage }) => {
  const onNavigate = nav => {
    setActivePage(1)
    setActiveNav(nav)
  }

  return (
    <div className='mt-4'>
      <Nav pills className='nav-pills-primary mt-2'>
        <NavItem>
          <NavLink 
            href='#' 
            onClick={() => onNavigate('general')}
            active={
              activeNav === 'general' ? 
              true 
              : 
              false
            }
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink 
            href='#' 
            onClick={() => onNavigate('bonus')}
            active={
              activeNav === 'bonus' ? 
              true 
              : 
              false
            }
          >
            Bonus
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

export default UpdatesNav