import React from 'react'
import { Table, Collapse, CardBody, Card } from 'reactstrap'

const SupportTypeTable = ({ contact, showAllLanguages, languages3Values, languagesValues, setShowAllLanguages, vip }) => (
  <Table responsive borderless>
    <tbody>
      <tr>
        <td className='w-25'>Contact info</td>
        <td className='text-left'>{contact}</td>
      </tr>
      <tr>
        <td className='w-25'>Languages</td>
        <td className='text-left'>
          {!showAllLanguages && 
            <>
              {languages3Values.join(', ')}
              {languagesValues.length > 3 &&
                <i 
                  className='fas fa-angle-down' 
                  style={{color:'#f8802f', marginLeft:'2em'}} 
                  id='openLanguages'
                  onClick={() => setShowAllLanguages(true)}
                />
              }
            </>
          }
            <Collapse isOpen={showAllLanguages}>
              <Card>
                <CardBody>
                  {languagesValues.join(', ')}
                  <i 
                    className='fas fa-angle-up' 
                    style={{color:'#f8802f', marginLeft:'2em'}} 
                    id='closeLanguages' 
                    onClick={() => setShowAllLanguages(false)}
                  />
                </CardBody>
              </Card>
            </Collapse>
        </td>
      </tr>
      <tr>
        <td className='w-25'>Vip</td>
        <td className='text-left'>
          {vip ? 
            <i className='fas fa-check' style={{color:'green'}} />
          :
            <i className='fas fa-times' style={{color:'red'}} />
          }
        </td>
      </tr>
    </tbody>
  </Table>
)

export default SupportTypeTable