import React from 'react'
import { CardTitle } from 'reactstrap'

import GeneralFilters from './GeneralFilters'
import GeneralTable from './GeneralTable'

const General = ({ updates, filtersProps, emptyFilterState }) => (
  <>
    <GeneralFilters {...filtersProps} />
    {!emptyFilterState ?
      <GeneralTable updates={updates} /> 
    :
      <CardTitle className='m-4'>No info to display</CardTitle>
    }
  </>
)

export default General