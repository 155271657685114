import React from 'react'
import { Table, Collapse, CardBody, Card } from 'reactstrap'

const SoftwareProviderTable = ({ owner, established_year, showAllGames, games3Values, gamesValues, setShowAllGames }) => (
  <Table responsive borderless>
    <tbody>
      <tr>
        <td className='w-25'>Owner</td>
        <td className='text-left'>{owner}</td>
      </tr>
      <tr>
        <td className='w-25'>Established Year</td>
        <td className='text-left'>{established_year}</td>
      </tr>
      <tr>
        <td className='w-25 align-top'>Games</td>
        <td className='text-left w-100'>
          {!showAllGames && 
            <Card className='shadow-none'>
              {gamesValues.length > 3 &&
                <i 
                  className='fas fa-angle-down' 
                  style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}} 
                  id='openGames'
                  onClick={() => setShowAllGames(true)}
                />
              }
                <CardBody className='pt-0'>
                  {games3Values.join(', ')}
                </CardBody>
            </Card>
          }
            <Collapse isOpen={showAllGames}>
              <Card className='shadow-none'>
                <i 
                    className='fas fa-angle-up' 
                    style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}} 
                    id='closeGames' 
                    onClick={() => setShowAllGames(false)}
                  />
                <CardBody className='pt-0'>
                  {gamesValues.join(', ')}
                </CardBody>
              </Card>
            </Collapse>
        </td>
      </tr>
    </tbody>
  </Table>
)

export default SoftwareProviderTable