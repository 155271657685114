import React from 'react'
import { Table, FormGroup, Label, Input } from 'reactstrap'


const BrandsTable = ({ brandsOptions, handleOption, action, cannotSelect }) => (
  <div className='border h-100 mt-2'>
    <Table responsive>
      <tbody>
        {brandsOptions.map(option => (
          <tr key={option.value}>
          <td className='text-center'>
            <FormGroup check>
              <Label check>
                <Input 
                  type='checkbox' 
                  onChange={() => handleOption(option, action)}
                />
                {!cannotSelect &&
                  <span className='form-check-sign' />
                }
              </Label>
            </FormGroup>
          </td>
          <td>{option.label}</td>
          </tr>
          ))
        }
      </tbody>
    </Table>
  </div>
)

export default BrandsTable