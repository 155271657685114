import React from 'react'
import { Col } from 'reactstrap'
import { connect } from 'react-redux'
import ReactWizard from 'react-bootstrap-wizard'
import NotificationAlert from 'react-notification-alert'

import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import Brands from './Brands'

import UpdatesServices from 'services/updates.services'
import { setCurrentUser } from 'redux/user/user.actions'

import './SignUpWizard.scss'

class SignUpWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userBrands: this.props.brands
    }
  }

  finishButtonClick = allStates => {
    const brandsValues = []
    allStates.Brands.pickedOptions.map(brand => brandsValues.push(brand.value))

    const data = {
      brandIds: brandsValues.toString()
    }

    UpdatesServices.addBrands(data)
      .then(res => {
        let userBrands = []

        allStates.Brands.pickedOptions.map(brand => {
          return userBrands.push({
            id: brand.value,
            name: brand.label
          })
        })

        const user = {...this.props.user, brands: userBrands}
        this.props.setCurrentUser(user)

        this.refs.notificationAlert.notificationAlert({
          place: 'tr',
          message: (
            <div>
              Brands updated successfully!
            </div>
          ),
          type: 'success',
          autoDismiss: 10
        })
      })
  }

  render() {
    const steps = [
      { stepName: 'Brands', component: Brands, stepProps: { userBrands: this.state.userBrands }}
    ]

    return (
      <>
        <PanelHeader size='sm' />
        <div className='content'>
          <NotificationAlert ref='notificationAlert' />
          <Col xs={12} md={10} className='mr-auto ml-auto'>
            <ReactWizard
              steps={steps}
              navSteps
              progressbar
              title='Manage Brands'
              description='Select one or more Brands and their Casinos will be added automatically.'
              headerTextCenter
              color='blue'
              finishButtonClasses='btn-wd'
              finishButtonText='Done'
              finishButtonClick={this.finishButtonClick}
            />
          </Col>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.currentUser,
    brands: state.user.currentUser.brands
  }
}

const mapDispatchToProps = {
  setCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpWizard)

