import React from 'react'
import { Table, Button } from 'reactstrap'

import './casinos.styles.scss'

const CasinosTable = ({ casinos, onEdit, onView, userRole }) => (
  <div className='mx-4 mt-4'>
    <Table responsive>
      <thead className='text-primary'>
        <tr>
        {userRole === 'Content Admin' &&
          <th>ID</th>
        }
        <th>Name</th>
        <th>Group</th>
        <th>Brand</th>
        <th className='text-left'>Actions</th>
        </tr>
      </thead>
      {casinos &&
        <tbody>
          {casinos.map(casino => (
            <tr key={casino.id} className={`${casino.dissolved_year !== null ? 'table-danger' : ''}`}>
            {userRole === 'Content Admin' &&
              <td>{casino.id}</td>
            }
            <td>{casino.name}</td>
            <td>{casino.brand ? casino.brand.group.name : ''}</td> 
            <td>{casino.brand ? casino.brand.name : ''}</td>
            <td className='text-left'>
              <Button
                className='btn-icon btn-neutral'
                color='info'
                type='button'
                onClick={() => onView(casino.id)}
              >
                <i className='far fa-eye' />
              </Button>
              {userRole === 'Content Admin' &&
                <Button
                  className='btn-icon btn-neutral'
                  color='info'
                  type='button'
                  onClick={() => onEdit(casino.id)}
                >
                  <i className='far fa-edit' />
                </Button> 
              }
              {casino.domain !== null &&
                <Button 
                  className='btn-icon btn-neutral'
                  color='info'
                  type='button'
                  target='_blank'
                  href={casino.domain}
                >
                  <i className='fas fa-external-link-alt' />
                </Button>
              }
            </td>
            </tr>
            ))
          }
        </tbody>
      }
    </Table>
  </div>
)

export default CasinosTable