
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setCasinoOptions } from 'redux/casino/casino.actions'
import CasinosServices from 'services/casinos.services'

const useFetchOptions = () => {
  const [brandsOptions, setBrandsOptions] = useState([])
  const [groupsOptions, setGroupsOptions] = useState([])
  const [certificationsOptions, setCertificationsOptions] = useState([])
  const [programsOptions, setProgramsOptions] = useState([])
  const [licensesOptions, setLicensesOptions] = useState([])
  const [providersOptions, setProvidersOptions] = useState([])
  const [languagesOptions, setLanguagesOptions] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])
  const [statesOptions, setStatesOptions] = useState([])
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([])
  const [supportTypeOptions, setSupportTypeOptions] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    CasinosServices.getBrands()
    .then(res => {
      const brandsOptions = []
    
      res.data.data.map(brand => {
        return brandsOptions.push({
          value: brand.id,
          label: brand.name,
          group: brand.group
        })
      })

      setBrandsOptions(brandsOptions)
    })

    CasinosServices.getGroups()
    .then(res => {
      const groupsOptions = []

      res.data.data.map(group => {
        return groupsOptions.push({
          value: group.id,
          label: group.name
        })
      })
      setGroupsOptions(groupsOptions)
    })

    CasinosServices.getCertifications()
    .then(res => {
      const certificationsOptions = []

      res.data.data.map(certification => {
        return certificationsOptions.push({
          value: certification.id,
          label: certification.name
        })
      })
      setCertificationsOptions(certificationsOptions)
    })

    CasinosServices.getPrograms()
    .then(res => {
      const programsOptions = []

      res.data.data.map(program => {
        return programsOptions.push({
          value: program.id,
          label: program.name
        })
      })
      setProgramsOptions(programsOptions)
    })

    CasinosServices.getLicenses()
    .then(res => {
      const licensesOptions = []

      res.data.data.map(license => {
        return licensesOptions.push({
          value: license.id,
          label: license.name
        })
      })
      setLicensesOptions(licensesOptions)
    })

    CasinosServices.getProviders()
    .then(res => {
      const providersOptions = []

      res.data.data.map(provider => {
        return providersOptions.push({
          value: provider.id,
          label: provider.name
        })
      })
      setProvidersOptions(providersOptions)
    })

    CasinosServices.getLanguages()
    .then(res => {
      const languagesOptions = []

      res.data.data.map(language => {
        return languagesOptions.push({
          value: language.id,
          label: language.name
        })
      })
      setLanguagesOptions(languagesOptions)
    })

    CasinosServices.getCountries()
    .then(res => {
      const countriesOptions = []

      res.data.data.map(country => {
        return countriesOptions.push({
          value: country.id,
          label: country.name
        })
      })
      setCountriesOptions(countriesOptions)
    })

    CasinosServices.getStates()
    .then(res => {
      const statesOptions = []

      res.data.data.map(state => {
        return statesOptions.push({
          value: state.id,
          label: state.name
        })
      })
      setStatesOptions(statesOptions)
    })

    CasinosServices.getPaymentMethods()
    .then(res => {
      const paymentMethodOptions = []
    
      res.data.data.map(paymentMethod => {
        return paymentMethodOptions.push({
          value: paymentMethod.id,
          label: paymentMethod.name
        })
      })
      setPaymentMethodOptions(paymentMethodOptions)
    })

    CasinosServices.getCurrencies()
    .then(res => {
      const currencyOptions = []
    
      res.data.data.map(currency => {
        return currencyOptions.push({
          value: currency.id,
          label: currency.name
        })
      })
      setCurrencyOptions(currencyOptions)
    })

    CasinosServices.getSupportTypes()
    .then(res => {
      const supportTypeOptions = []
    
      res.data.data.map(supportType => {
        return supportTypeOptions.push({
          value: supportType.id,
          label: supportType.name
        })
      })
      setSupportTypeOptions(supportTypeOptions)
    })
  }, [])

  const options = {
    brandsOptions,
    groupsOptions,
    certificationsOptions,
    programsOptions,
    licensesOptions,
    providersOptions,
    languagesOptions,
    countriesOptions,
    statesOptions,
    paymentMethodOptions,
    supportTypeOptions,
    currencyOptions
  }

  dispatch(setCasinoOptions(options))
}

export default useFetchOptions