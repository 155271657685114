import Updates from "views/Updates/Updates";
import Casinos from "views/Casinos/Casinos";
import SignUpWizard from "views/Brands/SignUpWizard.js";
import LoginPage from "views/Pages/LoginPage.js";
import Bonuses from "views/Bonuses/Bonuses.js";

let routes = [
  {
    path: "/updates",
    name: "Updates",
    icon: "fas fa-bell",
    component: Updates,
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    icon: "fas fa-industry",
    component: SignUpWizard,
    layout: "/admin"
  },
  {
    path: "/casinos",
    name: "Casinos",
    icon: "fas fa-crown",
    component: Casinos,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
      }
    ]
  },
  {
    path: "/bonuses",
    name: "Bonuses",
    icon: "fas fa-hand-holding-usd",
    component: Bonuses,
    layout: "/admin",
  }
];

export default routes;
