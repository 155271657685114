import React from 'react'
import { Button } from 'reactstrap'


const filters = [
  { value: 'start_soon', label: 'Coming Soon' },
  { value: 'expire_soon', label: 'Expiring Soon' },
  { value: 'expired', label: 'Expired' }
]

const BonusFilters = ({ bonusFilter, setBonusFilter, setActivePage }) => {
  const onFilter = filter => {
    setActivePage(1)
    setBonusFilter(filter)
  }

  return (
    <>
      {filters.map((filter, index) => (
        <Button
          key={index}
          className='btn-neutral'
          color={(bonusFilter === filter.value) ? 'primary' : 'tumblr'}
          onClick={() => onFilter(filter.value)}
        >
          {filter.label}
        </Button>
      ))
    }
    </>
  )
}

export default BonusFilters