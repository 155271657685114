import React from 'react'
import { Row, Button } from 'reactstrap'

import EmptyStateList from 'assets/img/empty-state-list.svg'
import EmptyStateTab from 'assets/img/empty-state-tab.svg'

const EmptyState = ({ props, type, children }) => (
  <Row className=' m-5 d-flex flex-column align-items-center justify-content-center'>
    <img src={type === 'list' ? EmptyStateList : EmptyStateTab} alt='empty-state' />
    <div className={`m-5 ${type === 'list' ? 'w-25' : 'w-75' } text-center`}>
      {children}
      {type === 'list' &&
        <Button 
          color='primary' 
          onClick={() => props.history.push('/admin/brands')}
        > 
          Add Brands
        </Button>
      }
    </div>
  </Row>
)

export default EmptyState