import React from 'react'
import { Row, Nav, NavItem, NavLink, Button } from 'reactstrap'


const CasinosNav = ({ activeNav, setActiveNav, onEdit, id }) => (
  <Row className='justify-content-between'>
    <Nav pills className='nav-pills-primary sticky-top navbar-light bg-white'>
      <NavItem className='mt-2 mb-4'>
        <NavLink 
          href='#' 
          onClick={() => setActiveNav('details')}
          active={
            activeNav === 'details' ? 
            true 
            : 
            false
          }
        >
          Casino Details
        </NavLink>
      </NavItem>
      <NavItem className='mt-2 mb-4'>
        <NavLink 
          href='#' 
          onClick={() => setActiveNav('paymentMethods')}
          active={
            activeNav === 'paymentMethods' ? 
            true 
            : 
            false
          }
        >
          Payment Methods
        </NavLink>
      </NavItem>
      <NavItem className='mt-2 mb-4'>
        <NavLink 
          href='#' 
          onClick={() => setActiveNav('supportTypes')}
          active={
            activeNav === 'supportTypes' ? 
            true 
            : 
            false
          }
        >
          Support Types
        </NavLink>
      </NavItem>
    </Nav>
    <Nav>
      <NavItem className='mt-2 mb-4'>
        <Button
          className='btn-icon btn-neutral'
          color='info'
          type='button'
          onClick={() => onEdit(id)}
        >
          <i className='far fa-edit' />
        </Button>
      </NavItem>
    </Nav>
  </Row>
)

export default CasinosNav