/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

const today = new Date()

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid}>
          <div className="copyright">
            &copy; {today.getFullYear()}, Powered by {""}
            <a href="https://ginbits.com/" target="_blank">
              Ginbits.
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
