import React from 'react'
import { Row, Col, Button } from 'reactstrap'

import BrandsTable from './BrandsTable'

import CasinosServices from 'services/casinos.services'

class Brands extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      brandsOptions: [],
      pickedOptions: [],
      selectedOptions: [],
      action: ''
    }
  }

  componentDidMount() {
    const userBrands = this.props.userBrands ? this.props.userBrands.map(brand => brand.id).toString() : undefined
    
    CasinosServices.getBrands()
      .then(res => {
        const allBrands = res.data.data
        let brandsOptions = []
        let pickedOptions = []

        if (userBrands) {
          const pickedBrandsIds = userBrands.split(',').map(Number)
          const brandsFiltered = allBrands.filter(({id}) => !pickedBrandsIds.includes(id))
          const pickedFiltered = allBrands.filter(({id}) => pickedBrandsIds.includes(id))
        
          brandsFiltered.map(brand => {
            return brandsOptions.push({
              value: brand.id,
              label: brand.name
            })
          })

          pickedFiltered.map(brand => {
            return pickedOptions.push({
              value: brand.id,
              label: brand.name
            })
          })
        } else {
          allBrands.map(brand => {
            return brandsOptions.push({
              value: brand.id,
              label: brand.name
            })
          })
        }
        
        this.setState({ brandsOptions, pickedOptions })
    })
  }   

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOptions !== this.state.selectedOptions && this.state.selectedOptions.length === 0) {
      this.setState({ action: '' })
    }
  }

  handleOption = (option, action) => {
    const { selectedOptions } = this.state

    this.setState({ action: action })

    if (selectedOptions.includes(option)) {
      const updatedSelectedOptions = selectedOptions.filter(selected => selected.value !== option.value)
      this.setState({ selectedOptions: updatedSelectedOptions })
    } else {
      this.setState({ selectedOptions: [...selectedOptions, option] })
    }
  }

  addSelectedOptions = () => {
    const { brandsOptions, pickedOptions, selectedOptions } = this.state

    const updatedBrandsOptions = brandsOptions.filter(option => !selectedOptions.includes(option))

    this.setState({ 
      brandsOptions: updatedBrandsOptions,
      pickedOptions: pickedOptions.concat(selectedOptions),
      selectedOptions: [],
      action: ''
    })
  }

  removePickedOptions = () => {
    const { brandsOptions, pickedOptions, selectedOptions } = this.state

    const updatedPickedOptions = pickedOptions.filter(option => !selectedOptions.includes(option))

    this.setState({ 
      pickedOptions: updatedPickedOptions,
      brandsOptions: brandsOptions.concat(selectedOptions),
      selectedOptions: [],
      action: ''
    })
  }

  render() {
    const { brandsOptions, pickedOptions, action } = this.state
    const { handleOption, addSelectedOptions, removePickedOptions } = this

    return (
      <>
        <Row className='px-5'>
          <Col xs={12} md={5}>
            <h4>Available Brands</h4>
          </Col>
          <Col xs={12} md={2} />
          <Col xs={12} md={5}>
            <h4 className='pl-3'>Selected Brands</h4>
          </Col>
        </Row>
        <Row className='px-5'>
          <Col xs={12} md={5}>
            <BrandsTable 
              brandsOptions={brandsOptions}  
              handleOption={handleOption}
              action='add'
              cannotSelect={action === 'remove' ? true : false}
            />
          </Col>
          <Col xs={12} md={2} className='text-center'>
            <Button
              className='btn-neutral'
              color='primary'
              size='lg'
              type='button'
              disabled={this.state.action === 'remove'}
              onClick={() => addSelectedOptions()}
            >
              <i className='fas fa-chevron-right fa-2x' />
            </Button>
            <Button
              className='btn-neutral'
              color='primary'
              size='lg'
              type='button'
              disabled={this.state.action === 'add'}
              onClick={() => removePickedOptions()}
            >
              <i className='fas fa-chevron-left fa-2x' />
            </Button>
          </Col>
          <Col xs={12} md={5}>
            {pickedOptions.length !== 0 ?
                <BrandsTable 
                  brandsOptions={pickedOptions}  
                  handleOption={handleOption}
                  action='remove'
                  cannotSelect={action === 'add' ? true : false}
                />
              :
                <div className='border h-100 mt-2 d-flex align-items-center justify-content-center'>
                  <div>No Brands added yet</div>
                </div>
            }
          </Col>
        </Row>
      </>
    )
  }
}

export default Brands
