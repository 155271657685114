import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { 
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Form
} from 'reactstrap'

import Select from 'react-select'
import Switch from 'react-bootstrap-switch'

const CasinoInfo = ({ 
  handleOnChange, 
  handleSwitch,
  brand,
  brandsOptions,
  setBrand,
  certifications,
  certificationsOptions,
  setCertifications,
  program,
  programsOptions,
  setProgram,
  licenses,
  licensesOptions,
  setLicenses,
  providers,
  providersOptions,
  setProviders,
  languages,
  languagesOptions,
  setLanguages,
  countries,
  countriesOptions,
  setCountries,
  states,
  statesOptions,
  setStates,
  inputs,
  setInputs,
  validUrls,
  setValidUrls
 }) => {
  const casino = useSelector(state => state.casino.casino)
 
  const checkValidUrl = url => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    
    return !!pattern.test(url);
  }

  const handleOnBlur = event => {
    const { name, value } = event.target
    const validUrl = checkValidUrl(value)
    setValidUrls({ ...validUrls, [name]: validUrl })
  }

  useEffect(() => {
    if (casino) { 
      const { 
        name,
        brand, 
        certifications, 
        affiliate_programs, 
        licenses,
        software_providers,
        languages,
        countries,
        established_year,
        dissolved_year,
        domain,
        manual_flushing,
        jackpot_amount,
        terms_url,
        instant_play,
        desktop_download,
        loyalty_program
      } = casino

      if (brand) { setBrand({ value: brand.id, label: brand.name, group: brand.group })}
      if (certifications) { setCertifications(certifications.map(({ id, name }) => { return { value: id, label: name }}))}
      if (affiliate_programs.length !== 0) { setProgram({ value: affiliate_programs[0].id, label: affiliate_programs[0].name })}
      if (licenses) { setLicenses(licenses.map(({ id, name }) => { return { value: id, label: name }}))}
      if (software_providers) { setProviders(software_providers.map(({ id, name }) => { return { value: id, label: name }}))}
      if (languages) { setLanguages(languages.map(({ id, name }) => { return { value: id, label: name }}))}
      if (countries) { setCountries(countries.map(({ id, name }) => { return { value: id, label: name }}))}
      if (countries && countries.some(country => country.name === 'United States')) { 
        const states = countries.find(country => country.name === 'United States').states
        if(states) { setStates(states.map(({ id, name }) => { return { value: id, label: name }}))}
      }
      
      setInputs({
        casino: name, 
        established: established_year,
        dissolved: dissolved_year,
        website: domain,
        flushing: manual_flushing,
        jackpot: jackpot_amount,
        terms: terms_url,
        play: instant_play,
        download: desktop_download,
        loyalty: loyalty_program
      })  
    }  
  }, [casino, setBrand, setCertifications, setCountries, setInputs, setLanguages, setLicenses, setProgram, setProviders, setStates])

  return (
    <Form className='form-horizontal mt-5'>
        <Row>
          <Label md='4'>Brand *</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                className='primary'
                placeholder='Select a Brand'
                name='brand'
                value={brand}
                options={brandsOptions}
                onChange={value => setBrand(value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Group</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='group' 
                id='group' 
                value={brand ? brand.group.name : ''}
                placeholder='Group will be assigned automatically'
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Label md='4'>Name *</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='casino' 
                id='casino' 
                value={inputs.casino}
                placeholder='Enter Casino name' 
                onChange={handleOnChange}
              />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Label md='4'>Affiliate Program</Label>
          <Col md='8'>
            <FormGroup>
                <Select
                  isClearable
                  className='info'
                  placeholder='Select an Affiliate Program'
                  name='program'
                  value={program}
                  options={programsOptions}
                  onChange={value => setProgram(value)}
                />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Label md='4'>Established Year</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='established' 
                id='established' 
                value={inputs.established}
                placeholder="Enter Casino's established year" 
                onChange={handleOnChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Dissolved Year</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='dissolved' 
                id='dissolved' 
                value={inputs.dissolved}
                placeholder="Enter Casino's dissolved year" 
                onChange={handleOnChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Website</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='website' 
                id='website' 
                value={inputs.website}
                placeholder="Enter Casino’s website"
                onChange={handleOnChange} 
                onBlur={handleOnBlur}
              />
              {!validUrls.website &&
                <Label className='ml-4 text-danger'>Invalid URL</Label>
              }
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Manual Flashing</Label>
          <Col md='8' className='m-2'>
            <FormGroup>
              <Switch 
                name='flushing'
                defaultValue={false}
                value={inputs.flushing}
                onText='YES'
                offText='NO'
                onColor='success'
                offColor='info'
                onChange={(el, state) => handleSwitch(el, state)} 
              /> 
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Loyalty Program</Label>
          <Col md='8' className='m-2'>
            <FormGroup>
              <Switch 
                name='loyalty'
                defaultValue={false}
                value={inputs.loyalty}
                onText='YES'
                offText='NO'
                onColor='success'
                offColor='info'
                onChange={(el, state) => handleSwitch(el, state)} 
              /> 
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Desktop Download</Label>
          <Col md='8' className='m-2'>
            <FormGroup>
              <Switch 
                name='download'
                defaultValue={false}
                value={inputs.download}
                onText='YES'
                offText='NO'
                onColor='success'
                offColor='info'
                onChange={(el, state) => handleSwitch(el, state)} 
              />  
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Instant Play</Label>
          <Col md='8' className='m-2'>
            <FormGroup>
              <Switch 
                name='play'
                defaultValue={false}
                value={inputs.play}
                onText='YES'
                offText='NO'
                onColor='success'
                offColor='info'
                onChange={(el, state) => handleSwitch(el, state)} 
              /> 
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Jackpot Amount</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='jackpot' 
                id='jackpot' 
                value={inputs.jackpot}
                placeholder='Enter Jackpot amount'
                onChange={handleOnChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Certifications</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                isMulti
                className='info'
                placeholder='Select one or more Certifications'
                name='certification'
                value={certifications}
                options={certificationsOptions}
                onChange={value => setCertifications(value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Licenses</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                isMulti
                className='info'
                placeholder='Select one or more Licenses'
                name='license'
                value={licenses}
                options={licensesOptions}
                onChange={value => setLicenses(value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Terms URL</Label>
          <Col md='8'>
            <FormGroup>
              <Input 
                type='text' 
                name='terms' 
                id='terms' 
                value={inputs.terms}
                placeholder='Enter Terms & Conditions link'
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
              {!validUrls.terms &&
                <Label className='ml-4 text-danger'>Invalid URL</Label>
              }
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label md='4'>Countries</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                isMulti
                className='info'
                placeholder='Select one or more Countries'
                name='countries'
                value={countries}
                options={countriesOptions}
                onChange={value => setCountries(value)}
              />
            </FormGroup>
          </Col>
        </Row>

        {countries && countries.some(country => country.label === 'United States') &&
          <Row>
            <Label md='4'>States</Label>
            <Col md='8'>
              <FormGroup>
                <Select
                  isMulti
                  className='info'
                  placeholder='Select one or more States'
                  name='states'
                  value={states}
                  options={statesOptions}
                  onChange={value => setStates(value)}
                />
              </FormGroup>
            </Col>
          </Row>
        }

        <Row>
          <Label md='4'>Languages</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                isMulti
                className='info'
                placeholder='Select one or more Languages'
                name='languages'
                value={languages}
                options={languagesOptions}
                onChange={value => setLanguages(value)}
              />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Label md='4'>Software Providers</Label>
          <Col md='8'>
            <FormGroup>
              <Select
                isMulti
                className='info'
                placeholder='Select one or more Providers'
                name='providers'
                value={providers}
                options={providersOptions}
                onChange={value => setProviders(value)}
              />
            </FormGroup>
          </Col>
        </Row> 
    </Form>
  )
}

export default CasinoInfo