import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import CasinosNav from './CasinosNav'
import CasinoDetails from './CasinoDetails'
import CasinoSupportTypes from './CasinoSupportTypes'

import './casinoView.styles.scss'

const CasinoView = ({ detailsModal, closeDetailsModal, casinoView, onEdit }) => {
  const { name, support_types, id } = casinoView
  
  const [activeNav, setActiveNav] = useState('details')
  const navProps = { activeNav, setActiveNav, onEdit, id } 

  const viewComponent = () => {
    switch (activeNav) {
      case 'details':
        return <CasinoDetails casinoView={casinoView} />
      case 'supportTypes':
        return <CasinoSupportTypes supportTypes={support_types} />
      default:
        return null
    }
  }

  return(
    <Modal
      modalClassName='right'
      isOpen={detailsModal}
      size='lg'
      toggle={closeDetailsModal}
      >
      <ModalHeader>
        <i 
          className='fas fa-angle-double-right mr-2' 
          role='button'
          onClick={() => closeDetailsModal()}
        />
        {name}
      </ModalHeader> 
      <ModalBody>
        <div className='mx-4 mt-4'>
          <CasinosNav {...navProps} />
          {viewComponent()}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CasinoView