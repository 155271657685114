import React from 'react'
import { Table } from 'reactstrap'

const PaymentMethodTable = ({ gamblingProducts }) => (
  <Table responsive style={{fontSize: '12px'}}>
    <thead className='text-primary'>
      <tr>
      <th>Min</th>
      <th>Max</th>
      <th>Fee</th>
      <th>Time (h)</th>
      <th>Currency</th>
      </tr>
    </thead>
    <tbody>
      {gamblingProducts.map((product, key) => (
        <tr key={key}>
        <td>{product.min_amount}</td>
        <td>{product.max_amount}</td>
        <td>{product.fee_amount}</td>
        <td>{product.processing_time}</td>
        <td>{product.currency}</td>
        </tr>
        ))
      }
    </tbody>
  </Table>
)

export default PaymentMethodTable