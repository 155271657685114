import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { 
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input
} from 'reactstrap'

import Select from 'react-select'
import Switch from 'react-bootstrap-switch'

const SupportTypes = ({ 
  supportTypes, 
  setSupportTypes, 
  supportTypeOptions, 
  languagesOptions
}) => {
  const casino = useSelector(state => state.casino.casino)

  const addSupportType = () => setSupportTypes([...supportTypes, { languages: [], vip: false }])

  const deleteSupportType = index => {
    const supportTypesUpdated = [...supportTypes]
    supportTypesUpdated.splice(index, 1)
    setSupportTypes(supportTypesUpdated)
  }

  const onSupportType = (option, index) => {
    const supportTypesUpdated = [...supportTypes]
    supportTypesUpdated[index].support_type_category_id = option.value
    setSupportTypes(supportTypesUpdated)
  }

  const onSupportLanguage = (options, index) => {
    const supportTypesUpdated = [...supportTypes]
    supportTypesUpdated[index].languages = options
    setSupportTypes(supportTypesUpdated)
  }

  const handleOnChange = (event, index) => {
    const supportTypesUpdated = [...supportTypes]
    const { name, value, type } = event.target

    supportTypesUpdated[index][name] = type === "number" ? parseInt(value, 10) : value
    setSupportTypes(supportTypesUpdated)
  }

  const onVIP = (elem, state, index) => {
    const supportTypesUpdated = [...supportTypes]

    supportTypesUpdated[index].vip = state
    setSupportTypes(supportTypesUpdated)
  }

  useEffect(() => { 
    if (casino) {
      const { support_types } = casino

       const fillSupportTypes = supportTypes => {
        supportTypes.forEach(supportType => {
          if (supportType.name) {
            supportType.support_type_category_id = 
            supportTypeOptions.find(type => type.label === supportType.name).value
          }
    
          if (supportType.languages) {
            const languagesUpdated = supportType.languages.map(({ id, name }) => { return { value: id, label: name }})
            supportType.languages = languagesUpdated
          }
    
          delete supportType.name
        })
      }

      if (supportTypeOptions.length !==0) {
        fillSupportTypes(support_types)
      }
      setSupportTypes(support_types) 
    }
  }, [casino, supportTypeOptions, setSupportTypes])

  return (
    <>
      <Row className='justify-content-between'>
        <Col>
          <h4 tag='h4'>Support Type</h4>
        </Col>
      </Row>
      {supportTypes.map((support, index) => {
        const typeValue = supportTypeOptions.find(supportType => supportType.value === support.support_type_category_id)
      
        return (
          <Card key={index}>
            <CardHeader>
              <Button
                className='btn-icon btn-neutral'
                type='button'
                onClick={() => deleteSupportType(index)}
              >
                <i className='fas fa-times fa-2x' />
              </Button> 
            </CardHeader>
            <CardBody>
              <Form className='form-horizontal'> 
                <Row>
                  <Label md='4'>Type</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Select
                        className='primary'
                        placeholder='Select Support Type'
                        name='supportType'
                        value={typeValue}
                        options={supportTypeOptions}
                        onChange={value => onSupportType(value, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>   
          
                <Row>
                  <Label md='4'>Languages</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Select
                        isMulti
                        className='info'
                        placeholder='Select one or more Languages'
                        name='languages'
                        value={support.languages}
                        options={languagesOptions}
                        onChange={value => onSupportLanguage(value, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>Contact Info</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Input 
                        type='text' 
                        name='contact' 
                        id='contact' 
                        value={support.contact}
                        placeholder='Enter contact details' 
                        onChange={event => handleOnChange(event, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>VIP</Label>
                  <Col md='8' className='m-2'>
                    <FormGroup>
                      <Switch 
                        name='vip'
                        value={support.vip}
                        onText='YES'
                        offText='NO'
                        onColor='success'
                        offColor='info'
                        onChange={(el, state) => onVIP(el, state, index)} 
                      /> 
                    </FormGroup>
                  </Col>
                </Row>

              </Form>
            </CardBody>
          </Card>
        )
      })}
      <Col style={{ marginTop: '2%', textAlign: 'center' }}>
          <Button 
            color='primary'
            onClick={addSupportType}
          >
            Add Support Type
          </Button>
        </Col>  
    </>
  )
}

export default SupportTypes