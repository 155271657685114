import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { 
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input
} from 'reactstrap'

import Select from 'react-select'

const PaymentMethods = ({ 
  paymentMethods,   
  setPaymentMethods,
  paymentMethodOptions,  
  currencyOptions
}) => {
  const casino = useSelector(state => state.casino.casino)

  const addPaymentMethod = () => setPaymentMethods([...paymentMethods, { method: 'Deposit', fee_type: 'Percent' }])

  const deletePaymentMethod = indexMethod => {
    const paymentMethodsUpdated = [...paymentMethods]
    paymentMethodsUpdated.splice(indexMethod, 1)
    setPaymentMethods(paymentMethodsUpdated)  
  }

  const onPaymentMethod = (option, index) => {
    const paymentMethodsUpdated = [...paymentMethods]
    paymentMethodsUpdated[index].id = option.value
    setPaymentMethods(paymentMethodsUpdated)
  }

  const onCurrency = (option, index) => {
    const paymentMethodsUpdated = [...paymentMethods]
    paymentMethodsUpdated[index].currency_id = option.value
    setPaymentMethods(paymentMethodsUpdated)
  }

  const handleOnChange = (event, index) => {
    const paymentMethodsUpdated = [...paymentMethods]
    const { name, value, type } = event.target

    if (name === 'fee_amount' || name === 'min_amount' || name === 'max_amount') {
      paymentMethodsUpdated[index][name] = parseFloat(value)
    } else {
      paymentMethodsUpdated[index][name] = type === "number" ? parseInt(value, 10) : value
    }
    setPaymentMethods(paymentMethodsUpdated)
  }

  useEffect(() => { 
    if (casino) {
      const { payment_methods } = casino

      const fillPaymentMethods = paymentMethods => {
        paymentMethods.forEach(paymentMethod => {
          if (paymentMethod.currency_name) {
            paymentMethod.currency_id = 
            currencyOptions.find(currency => currency.label === paymentMethod.currency_name).value
          }
    
          if (paymentMethod.name) {
            paymentMethod.id = 
            paymentMethodOptions.find(method => method.label === paymentMethod.name).value
          }

          if (paymentMethod.fee_amount) {
            paymentMethod.fee_amount = paymentMethod.fee_amount.replace('%', '')
            parseInt(paymentMethod.fee_amount, 10)
          }
    
          delete paymentMethod.name
          delete paymentMethod.currency_name
        })
      }

      if (currencyOptions.length !== 0 && paymentMethodOptions.length !==0) {
        fillPaymentMethods(payment_methods)
      }

      setPaymentMethods(payment_methods)
    }
  }, [casino, currencyOptions, paymentMethodOptions, setPaymentMethods])

  return (
    <>
      <Row className='justify-content-between'>
        <Col>
          <h4 tag='h4'>Payment Method</h4>
        </Col>
      </Row>
      {paymentMethods.map((method, index) => {
        const methodValue = paymentMethodOptions.find(paymentMethod => paymentMethod.value === method.id)
        const currencyValue = currencyOptions.find(currency => currency.value === method.currency_id )
      
        return(
          <Card key={index}>
            <CardHeader>
              <Button
                className='btn-icon btn-neutral'
                type='button'
                onClick={() => deletePaymentMethod(index)}
              >
                <i className='fas fa-times fa-2x' />
              </Button> 
            </CardHeader>
            <CardBody>
              <Form className='form-horizontal'> 
                <Row>
                  <Label md='4'>Payment Method</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Select
                        className='primary'
                        placeholder='Select Payment Method'
                        name='paymentMethod'
                        value={methodValue}
                        options={paymentMethodOptions}
                        onChange={value => onPaymentMethod(value, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Label md='4'>Type</Label>
                  <Col md='8'>
                    <FormGroup check className='form-check-radio' inline>
                      <Label check>
                        <Input
                          checked={method.method === 'Deposit' ? true : false}
                          id='deposit'
                          name='method'
                          type='radio'
                          value='Deposit'
                          onChange={event => handleOnChange(event, index)}
                        />
                        Deposit <span className='form-check-sign' />
                      </Label>
                    </FormGroup>
                    <FormGroup check className='form-check-radio' inline>
                      <Label check>
                        <Input
                          checked={method.method === 'Withdrawal' ? true : false}
                          id='withdrawal'
                          name='method'
                          type='radio'
                          value='Withdrawal'
                          onChange={event => handleOnChange(event, index)}
                        />
                        Withdrawal <span className='form-check-sign' />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>Currency</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Select
                        className='primary'
                        placeholder='Select a Currency'
                        name='currency'
                        value={currencyValue}
                        options={currencyOptions}
                        onChange={value => onCurrency(value, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>Min Amount</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Input 
                        type='number' 
                        step='0.00001'
                        name='min_amount' 
                        id='min_amount' 
                        value={parseFloat(method.min_amount)}
                        placeholder='Enter min amount'
                        onChange={event => handleOnChange(event, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>Max Amount</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Input 
                        type='number' 
                        step='0.00001'
                        name='max_amount' 
                        id='max_amount' 
                        value={parseFloat(method.max_amount)}
                        placeholder='Enter max amount'
                        onChange={event => handleOnChange(event, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md='4'>Fee</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Input 
                        type='number' 
                        step='0.01'
                        name='fee_amount' 
                        id='fee_amount' 
                        value={parseFloat(method.fee_amount)}
                        placeholder='Enter the fee amount'
                        onChange={event => handleOnChange(event, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Label md='4'>Type of Fee</Label>
                  <Col md='8'>
                    <FormGroup check className='form-check-radio' inline>
                      <Label check>
                        <Input
                          checked={method.fee_type === 'Percent' ? true : false}
                          id='percent'
                          name='fee_type'
                          type='radio'
                          value='Percent'
                          onChange={event => handleOnChange(event, index)}
                        />
                        % <span className='form-check-sign' />
                      </Label>
                    </FormGroup>
                    <FormGroup check className='form-check-radio' inline>
                      <Label check>
                        <Input
                          checked={method.fee_type === 'Fixed' ? true : false}
                          id='fixed'
                          name='fee_type'
                          type='radio'
                          value='Fixed'
                          onChange={event => handleOnChange(event, index)}
                        />
                        Fixed <span className='form-check-sign' />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                     
                <Row>
                  <Label md='4'>Processing Time (H)</Label>
                  <Col md='8'>
                    <FormGroup>
                      <Input 
                        type='number' 
                        name='processing_time' 
                        id='processing_time' 
                        value={method.processing_time}
                        placeholder='Enter processing time in hours'
                        onChange={event => handleOnChange(event, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )
      })}
      <Col style={{ marginTop: '2%', textAlign: 'center' }}>
          <Button 
            color='primary'
            onClick={addPaymentMethod}
          >
            Add Payment Method
          </Button>
        </Col>  
    </>
  )
}

export default PaymentMethods