import React, { useState, useEffect, useCallback  } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import PaymentMethods from './PaymentMethods'
import SupportTypes from './SupportTypes'
import CasinoInfo from './CasinoInfo'
import FormButtons from './FormButtons'

import CasinosServices from 'services/casinos.services'
import { addCasino, updateCasino, setCasino } from 'redux/casino/casino.actions'
import UseShowAlert from 'effects/use-show-alert'

const CasinoForm = ({ formProps }) => {
  const { modal, openModal, closeModal, casinoId } = formProps
  const options = useSelector(state => state.casino.options)

  const brandsOptions = options ? options.brandsOptions : []
  const groupsOptions = options ? options.groupsOptions : []
  const certificationsOptions = options ? options.certificationsOptions : []
  const programsOptions = options ? options.programsOptions : []
  const licensesOptions = options ? options.licensesOptions : []
  const providersOptions = options ? options.providersOptions : []
  const languagesOptions = options ? options.languagesOptions : []
  const countriesOptions = options ? options.countriesOptions : []
  const statesOptions = options ? options.statesOptions : []
  const paymentMethodOptions = options ? options.paymentMethodOptions : []
  const supportTypeOptions = options ? options.supportTypeOptions : []
  const currencyOptions = options ? options.currencyOptions : []
 
  const [brand, setBrand] = useState()
  const [group, setGroup] = useState()
  const [certifications, setCertifications] = useState()
  const [program, setProgram] = useState()  
  const [licenses, setLicenses] = useState()
  const [providers, setProviders] = useState()
  const [languages, setLanguages] = useState()
  const [countries, setCountries] = useState()
  const [states, setStates] = useState()
  const [paymentMethods, setPaymentMethods] = useState([])
  const [supportTypes, setSupportTypes] = useState([])
  const [inputs,setInputs] = useState({})

  const dispatch = useDispatch()

  const handleSwitch = (elem, state) => setInputs({ ...inputs, [elem.props.name]: state })

  const handleOnChange = useCallback(event => {
    const { name, value, type } = event.target
    setInputs({ ...inputs, [name]: type === "number" ? parseInt(value, 10) : value })
  },[inputs])

  const showError = (code, message) => {
    const title = `Error ${code}`
    const text = `${message}. Please try later.`

    UseShowAlert('warning', title, text)
  }

  const onSaveCasino = event => {
    event.preventDefault()   

    const certificationsValues = []
    if (certifications) { 
      certifications.map(certification => certificationsValues.push(certification.value))
    }

    const licensesValues = []
    if (licenses) {
      licenses.map(license => licensesValues.push(license.value))
    }
    
    const providersValues = []
    if (providers) {
      providers.map(provider => providersValues.push(provider.value))
    }
    
    const languagesValues = []
    if (languages) {
      languages.map(language => languagesValues.push(language.value))
    }

    const countriesValues = []
    if (countries) {
      countries.map(country => countriesValues.push(country.value))
    }
    
    const statesValues = []
    if (states) {
      states.map(state => statesValues.push(state.value))
    }
    
    if (supportTypes) {
      supportTypes.map(supportType => {
        const languagesValues = []
        supportType.languages.map(language => languagesValues.push(language.value))
        return supportType.languages = languagesValues.toString()
      })
    }

    const data = {
      gambling_product_type_id: 1,
      brand_id: brand.value,
      name: inputs.casino,
      established_year: inputs.established,
      dissolved_year: inputs.dissolved,
      domain: inputs.website,
      manual_flushing: inputs.flushing,
      jackpot_amount: inputs.jackpot,
      loyalty_program: inputs.loyalty,
      instant_play: inputs.play,
      desktop_download: inputs.download,
      terms_url: inputs.terms,
      certifications: certificationsValues.toString(),
      affiliate_programs: program ? program.value : null,
      licenses: licensesValues.toString(),
      software_providers: providersValues.toString(),
      languages: languagesValues.toString(),
      countries: countriesValues.toString(),
      states: statesValues.toString(),
      payment_methods: paymentMethods,
      support_types: supportTypes
    }

    const finalData = Object.entries(data).reduce((a,[k,v]) => (v === "" || v === null ? a : (a[k]=v, a)), {})
    
    casinoId ?
      CasinosServices.saveCasino(casinoId, finalData)
      .then(res => {
        const { affiliate_programs, created_at, gambling_product_type, id, name, updated_at, brand } = res.data.data

        const casinoUpdated = {
          affiliate_programs,
          created_at,
          gambling_product_type,
          id,
          name,
          updated_at,
          brand
        }

        dispatch(updateCasino(casinoUpdated))
        closeModal()
      }).catch(error => {
        const { code, error_message } = error.response.data.meta
        showError(code, error_message)
      })
    : 
      CasinosServices.addCasino(finalData)
      .then(res => {
        const { affiliate_programs, created_at, gambling_product_type, id, name, updated_at, brand } = res.data.data

        const casinoAdded = {
          affiliate_programs,
          created_at,
          gambling_product_type,
          id,
          name,
          updated_at,
          brand
        }

        dispatch(addCasino(casinoAdded))
        closeModal()
      }).catch(error => {
        const { code, error_message } = error.response.data.meta
        showError(code, error_message)
      })
  }

  const checkMultiSelect = item => {
    if (item) {
      return item.length === 0
    } else {
      return item === null
    }
  } 
  
  const checkValidation = items => {
    if (items === 'paymentMethods') {
      return !paymentMethods.some(item => 
        item.id === undefined
      )
    } else {
      return !supportTypes.some(item => 
        item.support_type_category_id === undefined ||
        checkMultiSelect(item.languages) ||
        item.contact === undefined ||
        item.contact === ''
      )
    }
  }

  const paymentMethodsValid = checkValidation('paymentMethods')
  const supportTypesValid = checkValidation('supportTypes')
  const [validUrls, setValidUrls] = useState({ website: true, terms: true})

  const formIsValid = 
    Object.values(validUrls).every(item => item) &&
    inputs.casino !== undefined && inputs.casino !== '' &&
    brand !== undefined && paymentMethodsValid && supportTypesValid
      ? true
      : false


  const fetchCasino = useCallback(() => {
    CasinosServices.getCasino(casinoId)
      .then(res => {
        dispatch(setCasino(res.data.data))
        openModal()
      })
  }, [casinoId, dispatch])

  useEffect(() => {      
    setBrand()
    setCertifications()
    setProgram()
    setLicenses()
    setProviders()
    setLanguages()
    setCountries()
    setStates()
    setPaymentMethods([])
    setSupportTypes([])
    setInputs({})

    if (casinoId) {
      fetchCasino()
    }
  }, [casinoId, fetchCasino])

  const buttonsProps = { 
    onSaveCasino, 
    formIsValid 
  }

  const casinoInfoProps = { 
    handleOnChange, 
    handleSwitch,
    brand,
    brandsOptions,
    setBrand,
    group,
    groupsOptions,
    setGroup,
    certifications,
    certificationsOptions,
    setCertifications,
    program,
    programsOptions,
    setProgram,
    licenses,
    licensesOptions,
    setLicenses,
    providers,
    providersOptions,
    setProviders,
    languages,
    languagesOptions,
    setLanguages,
    countries,
    countriesOptions,
    setCountries,
    states,
    statesOptions,
    setStates,
    inputs,
    setInputs,
    validUrls,
    setValidUrls
  }

  const paymentMethodsProps = {
    paymentMethods, 
    setPaymentMethods,  
    paymentMethodOptions, 
    currencyOptions
  }

  const supportTypesProps = { 
    supportTypes, 
    setSupportTypes, 
    supportTypeOptions,
    languagesOptions
  }

  return (
    <Modal
      isOpen={modal}
      size='lg'
      toggle={closeModal}
      //style={{maxWidth: '1600px', width: '80%', margin: '10px auto'}}
      >
      <ModalHeader toggle={closeModal}>{casinoId ? 'Update' : 'Create'} Casino</ModalHeader> 
      <ModalBody>
        <FormButtons {...buttonsProps} />
        <CasinoInfo {...casinoInfoProps} />
        <SupportTypes {...supportTypesProps} />
        <PaymentMethods {...paymentMethodsProps} />
        <FormButtons {...buttonsProps} />
      </ModalBody>
    </Modal>
  )
}

export default CasinoForm