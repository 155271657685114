import React, { useState } from 'react'
import { Table, Button } from 'reactstrap'

import BonusDetails from './BonusDetails/BonusDetails'
import UpdatesServices from 'services/updates.services'

const BonusTable = ({ bonuses, bonusFilter }) => {
  const [bonus, setBonus] = useState()
  const [bonusDetails, setBonusDetails] = useState()
  const [modal, setModal] = useState(false)

  const closeModal = () => setModal(false)

  const openView = bonus => { 
    UpdatesServices.getBonusDetails(bonus.id)
      .then(res => {
        setBonus(bonus)
        setBonusDetails(res.data.data[0])
        setModal(true)
      })
  }

  return (
    <div className='mx-4'>
      {bonusDetails &&
        <BonusDetails  
          modal={modal}
          closeModal={closeModal}
          bonus={bonus}
          bonusDetails={bonusDetails}
        />
      }

      <Table responsive>
        <thead className='text-primary'>
            <tr>
              {bonusFilter === 'start_soon' ?
                <th>Starting Date</th>
                :
                <th>Expiring Date</th>
              }
              <th>Welcome</th>
              <th>Bonus Type</th>
              <th>Amount</th>
              <th>Casino</th>
              <th>Actions</th>
            </tr>
        </thead>
        <tbody>
          {bonuses.map(bonus => (
            <tr key={bonus.id}>
            <td>{bonusFilter === 'start_soon' ? bonus.starting_date : bonus.expiration_date}</td>
            <td>
              {bonus.welcome ? 
                <i className='fas fa-check' style={{color:'green', marginLeft: '3em'}} />
              :
                <i className='fas fa-times' style={{color:'red', marginLeft: '3em'}} />
              }
            </td>
            <td>{bonus.bonus_type === 'Tier' ? 'Tier' : bonus.bonus_type.name}</td>
            <td>{bonus.amount}</td>
            <td>{bonus.gambling_product.name}</td>
            <td className='text-left' >
              <Button
                className='btn-icon btn-neutral'
                color='info'
                type='button'
                onClick={() => openView(bonus)}
              >
                <i className='far fa-eye' />
              </Button> 
              <Button 
                className='btn-icon btn-neutral'
                color='info'
                type='button'
                target='_blank'
                href={bonus.terms_url}
              >
                <i className='fas fa-external-link-alt' />
              </Button>
            </td>
            </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default BonusTable