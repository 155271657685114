import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Alert
} from 'reactstrap'

import UsersServices from 'services/users.services'
import { setCurrentUser } from 'redux/user/user.actions'

import bgImage from 'assets/img/afflixbg.png'

const LoginPage = props => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [registerNow, setRegisterNow] = useState(false)

  const [usernameFocus, setUsernameFocus] = useState()
  const [passwordFocus, setPasswordFocus] = useState()

  const dispatch = useDispatch()

  const onLogin = () => {
    const data = {
      username,
      password
    }

    UsersServices.login(data)
      .then(res => {
        const { token, onboarding } = res.data.data
        localStorage.setItem('token', token)
        dispatch(setCurrentUser(res.data.data))
        
        onboarding ? 
          props.history.push('/admin/brands')
        :
          props.history.push('/admin')
      })
  }

  useEffect(() => {
    document.body.classList.add('login-page')

    if (props.location.state !== undefined) {
      setRegisterNow(true)
    }

    return () => {
      document.body.classList.remove('login-page')
    }
  }, [props])

  return (
    <>
      <div className='content'>
        <div className='login-page'>
          <Container>
            <Col xs={12} md={8} lg={4} className='ml-auto mr-auto'>
              <Alert 
                color='success'
                isOpen={registerNow}
              >
                Email verified! Enter your credentials to login.
              </Alert>
            </Col>
            <div className='container col-8'>
              <Form>
                <Card className='card-login bg-light'>
                  <CardHeader>
                    <div className='card-header'>
                      <h4 className='card-title text-dark text-center'>Log In</h4>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className='d-flex justify-content-center'>
                      <InputGroup
                        className={
                          'form-control-lg col-8 ' +
                          (usernameFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='now-ui-icons ui-1_email-85 text-dark' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='email'
                          name='username'
                          placeholder='Username'
                          onFocus={e => setUsernameFocus(true)}
                          onBlur={e => setUsernameFocus(false) }
                          value={username}
                          onChange={event => setUsername(event.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <div className='d-flex justify-content-center'>
                      <InputGroup
                        className={
                          'form-control-lg col-8 ' +
                          (passwordFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='now-ui-icons objects_key-25 text-dark' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='password'
                          name='password'
                          placeholder='Password'
                          onFocus={e => setPasswordFocus(true)}
                          onBlur={e => setPasswordFocus(false)}
                          value={password}
                          onChange={event => setPassword(event.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </CardBody>
                  <CardFooter className='text-center'>
                    <div className='d-flex justify-content-center'> 
                      <Button
                        block
                        color='primary'
                        size='lg'
                        className='mb-3 btn-round col-5'
                        onClick={()=>onLogin()}
                      >
                        Login
                      </Button>
                    </div>
                    <div className='card-footer text-dark text-center'>
                      <p>
                        Don't have an account yet? <a href='https://afflix.io/sign-up/' target='_blank' rel='noopener noreferrer' className='link footer-link text-primary' style={{ fontSize: '14px' }}>
                           Request a demo
                        </a>
                      </p>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </div>
          </Container>
        </div>
      </div>
      <div
        className='full-page-background'
        style={{ backgroundImage: 'url(' + bgImage + ')' }}
      />
    </>
  )
}

export default LoginPage
