import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter, 
  CardTitle,
  Button,
  Input, 
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'

import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import CasinoForm from './CasinoForm'
import CasinosTable from './CasinosTable'
import CasinoView from './CasinoView/CasinoView'
import Pagination from 'components/Pagination/Pagination'
import EmptyState from 'components/EmptyState/EmptyState'

import CasinosServices from 'services/casinos.services'
import useFetchOptions from 'effects/use-fetch-options.effect'
import { setCasinos, setCasino } from 'redux/casino/casino.actions'

import './casinos.styles.scss'

const Casinos = props => {
  useFetchOptions()
  const casinos = useSelector(state => state.casino.casinos)
  const userRole = useSelector(state => state.user.currentUser.role.name)
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [paginationLinks, setPaginationLinks] = useState([])
  const [emptyState, setEmptyState] = useState(false)
  const [showDissolved, setShowDissolved] = useState(false)

  const paginationProps = { activePage, setActivePage, paginationLinks }

  const [modal, setModal] = useState(false)
  const [detailsModal, setDetailsModal] = useState(false)
  const [casinoId, setCasinoId] = useState(null)
  const [casinoView, setCasinoView] = useState(null)
  const [search, setSearch] = useState()
  
  const dispatch = useDispatch()

  const openModal = () => setModal(true)
  const closeModal = () => {
    setCasinoId(null)
    dispatch(setCasino(null))
    setModal(false)
  }

  const openDetailsModal = () => setDetailsModal(true)
  const closeDetailsModal = () => {
    setDetailsModal(false)
    setCasinoView(null)
  }

  const onView = id => {
    CasinosServices.getCasino(id)
      .then(res => {
        setCasinoView(res.data.data)
        openDetailsModal()
      })
  }
  
  const onAdd = () => {
    setCasinoId(null)
    openModal()
  }
  
  const onEdit = id => setCasinoId(id)
  
  const handleOnSearch = event => {
    if(event.target.value === '') {
      fetchCasinos()
    } 
    setSearch(event.target.value)
  }

  const onSearch = event => {
    event.preventDefault() 

    setActivePage(1)
    fetchCasinosSearched()
  }

  const fetchCasinos = useCallback(() => {
    CasinosServices.getCasinos(activePage, showDissolved)
    .then(res => {
      if(res.data.data.length === 0) {
        setTotalPages(1)
        setEmptyState(true)
      } else {
        setTotalPages(Math.ceil(res.data.meta.totalRecords/20))
        setPaginationLinks(Array(Math.ceil(res.data.meta.totalRecords/20)).fill(1).map((x, y) => x + y))
        setEmptyState(false)
      }
      dispatch(setCasinos(res.data.data))
    })
  }, [dispatch, activePage, showDissolved])

  const fetchCasinosSearched = () => {
    CasinosServices.getCasinosSearched(search, activePage, showDissolved).then(res => {
      if(res.data.data.length === 0) {
        setTotalPages(1)
      } else {
        setTotalPages(Math.ceil(res.data.meta.totalRecords/20))
        setPaginationLinks(Array(Math.ceil(res.data.meta.totalRecords/20)).fill(1).map((x, y) => x + y))
      }
      dispatch(setCasinos(res.data.data))
    })
  }

  useEffect(() => { 
    if (search === '' || search === undefined) {      
      fetchCasinos()
    } 
    else {
      fetchCasinosSearched()
    }
  }, [fetchCasinos, activePage]) // eslint-disable-line react-hooks/exhaustive-deps

  const formProps = { modal, openModal, closeModal, casinoId } 
  const tableProps = { casinos, onEdit, onView, userRole }
  const casinoProps = { formProps }
  const detailsProps = { detailsModal, closeDetailsModal, casinoView, onEdit }
  const emptyStateProps = { props, type: 'list' }

  return(
    <>
      <PanelHeader size='sm' />
      <div className='content'>
        <CasinoForm {...casinoProps} />
        {casinoView &&
          <CasinoView {...detailsProps} />
        }
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>List Of Casinos</CardTitle>

               {userRole === 'Content Admin' &&
                  <Button 
                    color='primary' 
                    className='mr-5 float-right'
                    onClick={onAdd}
                  > 
                    Create New Casino
                  </Button>
                }
              </CardHeader>
              <CardBody>
                {casinos && !emptyState ?
                  <>
                    <Row>
                      <Col xs={12} md={5}>
                        <form onSubmit={onSearch}>
                          <InputGroup>
                            <Input 
                              type='text' 
                              name='search' 
                              id='search' 
                              value={search || ''}
                              placeholder='Search for Casinos by Casino, Group or Brand name' 
                              onChange={handleOnSearch}
                            />

                            <InputGroupAddon addonType='append'>
                              <InputGroupText>
                                <i 
                                  className='now-ui-icons ui-1_zoom-bold' 
                                  style={{cursor: 'pointer'}} 
                                  onClick={onSearch} 
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </form>
                      </Col>
                    </Row>
                    <CasinosTable {...tableProps} />
                  </>
                  :
                  <EmptyState {...emptyStateProps}>
                    <>
                      <p>You are one step away of endless data!</p>
                      <p>
                        Select the <strong>Brand(s)</strong> you want and their <strong>Casinos</strong> will be  
                        added <strong>automatically</strong> to this list.
                      </p>
                    </>
                  </EmptyState>
                }
              </CardBody>
                <CardFooter>
                  {totalPages > 1 &&
                    <Pagination {...paginationProps} />
                  }
                  {(casinos && casinos.length !== 0) && !emptyState &&
                    <div className='mx-4'>
                      <Switch 
                        name='dissolved'
                        value={showDissolved}
                        onText='YES'
                        offText='NO'
                        onColor='success'
                        offColor='info'
                        onChange={(el, state) => setShowDissolved(state)} 
                      /> 
                      <Label className='pl-2 font-weight-bold'>Show dissolved casinos</Label> 
                    </div>
                  }
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Casinos
