import React from 'react'


const SingleRows = ({ min_deposit_amount, max_bonus_amount, wager, max_cashout, coupon_code, live_chat }) => (
  <>
    <tr>
      <td className='w-25'>Min Deposit</td>
      <td className='text-left pl-5'>{min_deposit_amount!== '' ? min_deposit_amount : '-'}</td>
    </tr>
    <tr>
      <td className='w-25'>Max Deposit</td>
      <td className='text-left pl-5'>{max_bonus_amount!== '' ? max_bonus_amount : '-'}</td>
    </tr>
    <tr>
      <td className='w-25'>Wager</td>
      <td className='text-left pl-5'>{wager!== '' ? wager : '-'}</td>
    </tr>
    <tr>
      <td className='w-25'>Max Cashout</td>
      <td className='text-left pl-5'>{max_cashout!=='' ? max_cashout : '-'}</td>
    </tr>
    <tr>
      <td className='w-25'>Coupon</td>
      <td className='text-left pl-5'>{coupon_code!=='' ? coupon_code : '-'}</td>
    </tr>
    <tr>
      <td className='w-25'>Live Chat</td>
      <td className='text-left pl-5'>
        {live_chat ? 
          <>
            <i className='fas fa-check' style={{color:'green', marginRight: '1em'}} />
            Claim via Live chat 
          </>
        : 
          <i className='fas fa-times' style={{color:'red'}} />
        }
      </td>
    </tr>
  </>
)

export default SingleRows