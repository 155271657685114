import { CasinoActionTypes } from './casino.types'

const INITIAL_STATE = {
  casinos: null,
  casino: null,
  options: null
}

const { SET_CASINOS, ADD_CASINO, UPDATE_CASINO, SET_CASINO, SET_CASINO_OPTIONS } = CasinoActionTypes

const casinoReducer = (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case SET_CASINOS:
      return {
        ...state,
        casinos: action.payload
      }

    case ADD_CASINO:
      const updatedNewCasinos = [...state.casinos]
      updatedNewCasinos.push(action.payload)

      return { ...state, casinos: updatedNewCasinos }

    case UPDATE_CASINO:
      const updatedCasinos = [...state.casinos]
      const updateIndex = updatedCasinos.findIndex(casino => casino.id === action.payload.id)
      updatedCasinos[updateIndex] = action.payload
    
      return { ...state, casinos: updatedCasinos }

    case SET_CASINO:
      return {
        ...state,
        casino: action.payload
      }

    case SET_CASINO_OPTIONS:
      return {
        ...state,
        options: action.payload
      }
      
    default:
      return state
  }
}

export default casinoReducer