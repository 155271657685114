import React from 'react'
import { Table } from 'reactstrap'


const BonusesTable = ({ bonuses }) => (
  <div className='mx-4 mt-4'>
    <Table responsive>
      <thead className='text-primary'>
        <tr>
        <th>ID</th>
        <th>Starting Date</th>
        <th>Expiring Date</th>
        <th>Type</th>
        <th>Amount</th>
        <th>Casino</th>
        <th className='text-center'>Actions</th>
        </tr>
      </thead>
      {bonuses &&
      <tbody>
      {bonuses.map(bonus => (
        <tr key={bonus.id}>
        <td>{bonus.id}</td>
        <td>{bonus.starting_date}</td>
        <td>{bonus.expiration_date}</td>
        <td>{bonus.bonus_type.name}</td>
        <td>{bonus.amount}</td>
        <td>{bonus.gambling_product.name}</td>
        <td className='text-center'>   
        </td>
        </tr>
        ))
      }
    </tbody>
    }
    </Table>
  </div>
)

export default BonusesTable