import React, { useState } from 'react'
import { Table, Collapse, CardBody, Card } from 'reactstrap'


const CasinoDetails = ({ casinoView }) => {
  const { brand, affiliate_programs, domain, established_year, loyalty_program,
    desktop_download, instant_play, manual_flushing, app, total_games_available,
    jackpot_amount, certifications, licenses, terms_url, countries, software_providers, languages } = casinoView

  const certificationsValues = certifications.map(certification => { return certification.name })
  const licensesValues = licenses.map(license => { return license.name })
  const countriesValues = countries.map(country => { return country.name })
  const countries3Values = countriesValues.slice(0, 3)
  const [showAllCountries, setShowAllCountries] = useState(false)
  const USA = countries.find(country => country.name === 'United States')
  const states = USA ? USA.states : null
  const statesValues = states ? states.map(state => { return state.name }) : ''
  const states3Values = statesValues.slice(0, 3)
  const [showAllStates, setShowAllStates] = useState(false)
  const providersValues = software_providers.map(provider => { return provider.name })
  const providers3Values = providersValues.slice(0, 3)
  const [showAllProviders, setShowAllProviders] = useState(false)
  const languagesValues = languages.map(language => { return language.name })
  const languages3Values = languagesValues.slice(0, 3)
  const [showAllLanguages, setShowAllLanguages] = useState(false)



  return(
    <Table className='mt-4' id='casino-details-modal' responsive borderless>
      <tbody>
        <tr>
          <th className='text-right pr-4'>Brand</th>
          <td>{brand.name}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Group</th>
          <td>{brand.group.name}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Affiliate Program</th>
          <td>
            {affiliate_programs.lenght > 0 && 
              affiliate_programs[0].name
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Website</th>
          <td>{domain}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Establish Year</th>
          <td>{established_year}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Manual Flashing</th>
          <td>
            {manual_flushing ? 
              <i className='fas fa-check' style={{color:'green'}} />
            :
              <i className='fas fa-times' style={{color:'red'}} />
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Loyalty Program</th>
          <td>
            {loyalty_program ? 
              <i className='fas fa-check' style={{color:'green'}} />
            :
              <i className='fas fa-times' style={{color:'red'}} />
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Desktop Download</th>
          <td>
            {desktop_download ? 
              <i className='fas fa-check' style={{color:'green'}} />
            :
              <i className='fas fa-times' style={{color:'red'}} />
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Instant Play</th>
          <td>
            {instant_play ? 
              <i className='fas fa-check' style={{color:'green'}} />
            :
              <i className='fas fa-times' style={{color:'red'}} />
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4'>App</th>
          <td>
            {app === 'Android' ? 
              <i className='fab fa-android' />
            :
              <i className='fab fa-apple' />
            }
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4 text-nowrap'>Total Games Available</th>
          <td>{total_games_available}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Jackpot Amount</th>
          <td>{jackpot_amount}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Certifications</th>
          <td>{certificationsValues.join(', ')}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Licenses</th>
          <td>{licensesValues.join(', ')}</td>
        </tr>
        <tr>
          <th className='text-right pr-4'>Terms URL</th>
          <td>{terms_url}</td>
        </tr>
        <tr>
          <th className='text-right pr-4 align-top'>Countries</th>
          <td className='w-100'>
            {!showAllCountries && 
              <Card className='shadow-none'>
                {countriesValues.length > 3 &&
                  <i 
                    className='fas fa-angle-down' 
                    style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                    id='openCountries'
                    onClick={() => setShowAllCountries(true)}
                  />
                }
                  <CardBody className='pt-0'>
                    {countries3Values.join(', ')}
                  </CardBody>
              </Card>
            }
              <Collapse isOpen={showAllCountries}>
                <Card className='shadow-none'>
                  <i 
                      className='fas fa-angle-up' 
                      style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                      id='closeCountries' 
                      onClick={() => setShowAllCountries(false)}
                    />
                  <CardBody className='pt-0'>
                    {countriesValues.join(', ')}
                  </CardBody>
                </Card>
              </Collapse>
          </td>
        </tr>
        {states &&
          <tr>
            <th className='text-right pr-4 align-top'>States</th>
            <td className='w-100'>
              {!showAllStates && 
                <Card className='shadow-none'>
                  {statesValues.length > 3 &&
                    <i 
                      className='fas fa-angle-down' 
                      style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                      id='openStates'
                      onClick={() => setShowAllStates(true)}
                    />
                  }
                    <CardBody className='pt-0'>
                      {states3Values.join(', ')}
                    </CardBody>
                </Card>
              }
                <Collapse isOpen={showAllStates}>
                  <Card className='shadow-none'>
                    <i 
                        className='fas fa-angle-up' 
                        style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                        id='closeStates' 
                        onClick={() => setShowAllStates(false)}
                      />
                    <CardBody className='pt-0'>
                      {statesValues.join(', ')}
                    </CardBody>
                  </Card>
                </Collapse>
            </td>
          </tr>
        }
        <tr>
          <th className='text-right pr-4 align-top'>Software Providers</th>
          <td className='w-100'>
            {!showAllProviders &&
            <Card className='shadow-none'>
            {providersValues.length > 3 &&
                  <i 
                    className='fas fa-angle-down' 
                    style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                    id='openProviders'
                    onClick={() => setShowAllProviders(true)}
                  />
                }
                  <CardBody className='pt-0'>
                    {providers3Values.join(', ')}
                  </CardBody>
              </Card>
            }
              <Collapse isOpen={showAllProviders}>
                <Card className='shadow-none'>
                  <i 
                      className='fas fa-angle-up' 
                      style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                      id='closeProviders' 
                      onClick={() => setShowAllProviders(false)}
                    />
                  <CardBody className='pt-0'>
                    {providersValues.join(', ')}
                  </CardBody>
                </Card>
              </Collapse>
          </td>
        </tr>
        <tr>
          <th className='text-right pr-4 align-top'>Languages</th>
          <td className='w-100'>
          {!showAllLanguages && 
            <Card className='shadow-none'>
              {languagesValues.length > 3 &&
                <i 
                  className='fas fa-angle-down' 
                  style={{color:'#f8802f', marginLeft:'2em', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                  id='openLanguages'
                  onClick={() => setShowAllLanguages(true)}
                />
              }
                <CardBody className='pt-0'>
                  {languages3Values.join(', ')}
                </CardBody>
            </Card>
          }
            <Collapse isOpen={showAllLanguages}>
              <Card className='shadow-none'>
                <i 
                    className='fas fa-angle-up' 
                    style={{color:'#f8802f', cursor: 'pointer', fontSize: '20px', float: 'right'}}
                    id='closeLanguages'
                    onClick={() => setShowAllLanguages(false)}
                  />
                <CardBody className='pt-0'>
                  {languagesValues.join(', ')}
                </CardBody>
              </Card>
            </Collapse>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default CasinoDetails