import axios from './axios'

const register = data => axios.post('/auth/register', data)
const login = data => axios.post('auth/login', data)
const confirmRegistration = token => axios.get(`auth/confirm/registration/${token}`)

const UsersServices = {
  register,
  login,
  confirmRegistration
}

export default UsersServices
