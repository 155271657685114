import axios from 'axios'
import UseShowAlert from 'effects/use-show-alert'

const { REACT_APP_API_URL } = process.env

const api = axios.create({
  baseURL: REACT_APP_API_URL
})

api.interceptors.request.use (
  async (config) => {
    const token = await localStorage.getItem('token') 
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject (error)
  }
)

const responseHandler = response => {  
  const { code } = response.data.meta

  if (code === 403) {
    UseShowAlert('error', 'Unauthorized or Forbidden Access', 'Access denied.')
  }
  return response
}

const errorHandler = error => {  
  const { message } = error 
  const { code } = error.response.data.meta

  if(code === 400 || code === 401) {
    UseShowAlert('warning','Invalid credentials', 'Invalid username or password. Please try again.')
  } 
  else {
    const text = `The server responded with a status error ${message}. Please try again.`
    UseShowAlert('warning', 'Server Error', text)
  }
  
  return Promise.reject(error)
}

api.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
)

export default api