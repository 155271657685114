import React from 'react'
import { Row, Col, Button } from 'reactstrap'


const FormButtons = ({ onSaveCasino, formIsValid }) => {
  return (
    <Row>
      <Col>
        <Button 
          color='primary' 
          className='float-right'
          onClick={onSaveCasino}
          disabled={formIsValid ? false : true}
        > 
          SAVE
        </Button>
      </Col>
    </Row>
  )
}

export default FormButtons