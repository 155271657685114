import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import userReducer from './user/user.reducer'
import casinoReducer from './casino/casino.reducer'
import bonusReducer from './bonus/bonus.reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

const rootReducer = combineReducers({
  user: userReducer,
  casino: casinoReducer,
  bonus: bonusReducer
})

export default persistReducer(persistConfig, rootReducer)