import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'reactstrap'

import TierPagination from 'components/Pagination/TierPagination'


const TierBonus = ({ descendants }) => {
  const [activePage, setActivePage] = useState(1)
  const [activeBonus, setActiveBonus] = useState(descendants[0])
  const paginationLinks = Array(Math.ceil(descendants.length)).fill(1).map((x, y) => x + y)

  const paginationProps = { activePage, setActivePage, paginationLinks }
  const { bonus_type, min_deposit_amount, max_bonus_amount, wager, max_cashout, coupon_code, live_chat } = activeBonus

  useEffect(() => { 
    setActiveBonus(descendants[activePage-1])
  }, [activePage, descendants]) 
  
  return (
    <>
      <hr id='horizontal-rule' style={{width: '100%'}} />
      <Row className='justify-content-between'>
        <Col>
          <span 
            style={{backgroundColor: '#F3F3F3', padding: '0.4rem 4rem', borderRadius: '4rem'}}
          >
            {bonus_type.name}
          </span>
        </Col>
        <Col>
          <TierPagination {...paginationProps} />
        </Col>
      </Row>
      <Table responsive borderless className='mb-0'>
        <tbody>
          <tr>
            <td className='w-25'>Min Deposit</td>
            <td className='text-left pl-5'>{min_deposit_amount!== '' ? min_deposit_amount : '-'}</td>
          </tr>
          <tr>
            <td className='w-25'>Max Deposit</td>
            <td className='text-left pl-5'>{max_bonus_amount!== '' ? max_bonus_amount : '-'}</td>
          </tr>
          <tr>
            <td className='w-25'>Wager</td>
            <td className='text-left pl-5'>{wager!== '' ? wager : '-'}</td>
          </tr>
          <tr>
            <td className='w-25'>Max Cashout</td>
            <td className='text-left pl-5'>{max_cashout!=='' ? max_cashout : '-'}</td>
          </tr>
          <tr>
            <td className='w-25'>Coupon</td>
            <td className='text-left pl-5'>{coupon_code!=='' ? coupon_code : '-'}</td>
          </tr>
          <tr>
            <td className='w-25'>Live Chat</td>
            <td className='text-left pl-5'>
              {live_chat ? 
                <>
                  <i className='fas fa-check' style={{color:'green', marginRight: '1em'}} />
                  Claim via Live chat 
                </>
              : 
                <i className='fas fa-times' style={{color:'red'}} />
              }
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default TierBonus