import React from 'react'
import { CardTitle } from 'reactstrap'

import BonusFilters from './BonusFilters'
import BonusTable from './BonusTable'


const Bonus = ({ bonuses, bonusFilter, setBonusFilter, emptyFilterState, setActivePage }) => {
  const bonusFilterProps = { bonusFilter, setBonusFilter, setActivePage }
  const bonusTableProps = { bonuses, bonusFilter }

  return (
    <>
      <BonusFilters {...bonusFilterProps} />
      {!emptyFilterState ?
        <BonusTable {...bonusTableProps} /> 
      :
        <CardTitle className='m-4'>No info to display</CardTitle>
      }
    </>
  )
}

export default Bonus